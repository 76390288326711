<template>
  <div>
    <a-card title="产品小类详情" style="width: 80%; margin: 2% auto">
      <template slot="extra">
        <a-select
          v-model="languageSelect"
          allowClear
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="handleLanguage"
          :style="{ width: '8vw' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">{{
            language.label
          }}</a-select-option>
        </a-select>
      </template>
      <a-descriptions title="产品小类基础数据" bordered style="margin-top: 1%">
        <a-descriptions-item label="产品小类ID"> {{ form.id }} </a-descriptions-item>
        <a-descriptions-item label="产品小类名称"> {{ form.name }} </a-descriptions-item>
        <a-descriptions-item label="产品类别">
          {{ $constRepository.productSubClass.productSubClassKind(form.kind) }}
        </a-descriptions-item>
        <a-descriptions-item label="产品类型">
          {{ $constRepository.productSubClass.productSubClassType(form.type) }}
        </a-descriptions-item>
        <a-descriptions-item label="配网说明URL"> {{ form.distributionNetworkUrl }} </a-descriptions-item>
        <a-descriptions-item label="创建时间"> {{ form.createdTime | parseTime | orNA }} </a-descriptions-item>
        <a-descriptions-item label="上次修改时间">
          {{ form.lastModificationTime | parseTime | orNA }}
        </a-descriptions-item>
        <a-descriptions-item label="产品目录名称"> {{ form.productCategoryName }} </a-descriptions-item>
        <a-descriptions-item label="产品大类名称"> {{ form.productClassName }} </a-descriptions-item>
        <a-descriptions-item label="发布区域">
          <span v-if="form.regionIdentifiers">
            {{ form.regionIdentifiers.join(',') }}
          </span>
          <span v-else>-</span>
        </a-descriptions-item>
        <a-descriptions-item label="产品（物模型）Id"> {{ form.productId }} </a-descriptions-item>
        <a-descriptions-item label="备注"> {{ form.remark }} </a-descriptions-item>
      </a-descriptions>
      <div class="title">产品小类推荐负载类型</div>
      <a-table :data-source="form.recommendedLoadedDeviceTypes" :pagination="false" row-key="productId">
        <a-table-column key="productId" title="产品ID" data-index="productId" align="center"/>
        <a-table-column data-index="product" key="productName" title="产品名称" align="center">
          <template slot-scope="text">
            {{ text.productName }}
          </template>
        </a-table-column>
        <a-table-column data-index="product" key="protocol" title="产品控制协议类型" align="center">
          <template slot-scope="text">
            {{ productProtocol(text.protocol) }}
          </template>
        </a-table-column>
        <a-table-column data-index="product" key="type" title="产品类型" align="center">
          <template slot-scope="text">
            {{ productType(text.type) }}
          </template>
        </a-table-column>
        <a-table-column data-index="loadedDeviceType" key="loadedDeviceType" title="推荐负载类型" align="center">
          <template slot-scope="text,record">
            <span v-if="record.loadedDeviceTypeId">
              <a-tag color="#2db7f5">{{ `${text.loadedDeviceTypeName}(id:${text.loadedDeviceTypeId})` }}</a-tag>
            </span>
            <span v-else>-</span>
          </template>
        </a-table-column>
        <a-table-column key="operation" title="操作" align="center">
          <template slot-scope="text, record">
            <a-button
              v-if="$hasPermissions(['rcs:loaded_device_type:get']) && record.loadedDeviceTypeId"
              type="link"
              @click="handleProductDetail(record)"
              >详情</a-button
            >
          </template>
        </a-table-column>
      </a-table>
      <a-descriptions title="产品小类图片数据" bordered style="margin-top: 4%">
        <a-descriptions-item label="产品小类实物图" span="3">
          <div class="clearfix">
            <a-upload list-type="picture-card" accept="image/*" :file-list="solidImages" @preview="handlePreview">
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="产品小类线框图" span="3">
          <div class="clearfix">
            <a-upload list-type="picture-card" accept="image/*" :file-list="lineImages" @preview="handlePreview">
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="产品小类渲染图" span="3">
          <div class="clearfix">
            <a-upload list-type="picture-card" accept="image/*" :file-list="renderedImages" @preview="handlePreview">
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="产品小类缩略图">
          <div class="clearfix">
            <a-upload list-type="picture-card" accept="image/*" :file-list="thumbnails" @preview="handlePreview">
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </div>
        </a-descriptions-item>
      </a-descriptions>
      <a-button type="primary" style="margin-top: 2%" @click="$router.back()">返回</a-button>
    </a-card>
    <region-select-modal ref="RegionSelectModal" />
  </div>
</template>

<script>
import RegionSelectModal from '../region/components/RegionSelectModal';

export default {
  name: 'ProductSubClassDetail',
  components: { RegionSelectModal },
  async mounted() {
    if (this.$route.params.id) {
      this.listProductSubClassById(this.$route.params.id);
    }
    await this.getMetaData();
  },
  computed: {
    productType() {
      return (value) => {
        if (this.metaData.productTypes) {
          const typeName = this.metaData.productTypes.find((md) => md.value === value);
          return typeName ? typeName.name : '';
        }
        return '';
      };
    },
    productProtocol() {
      return (protocol) => {
        if (this.metaData.productProtocols) {
          const protocolsName = this.metaData.productProtocols?.find((md) => md.value === protocol);
          return protocolsName ? protocolsName.name : '';
        }
        return '';
      };
    },
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      previewVisible: false,
      previewImage: '',
      solidImages: [],
      lineImages: [],
      renderedImages: [],
      thumbnails: [],
      form: {
        name: undefined,
        kind: undefined,
        productClassId: undefined,
        type: undefined,
        distributionNetworkUrl: undefined,
        remark: undefined,
        productId: undefined,
        solidImages: [],
        lineImages: [],
        renderedImages: [],
        thumbnails: [],
        regionIdentifiers: [],
        metaData: [],
        recommendedLoadedDeviceTypes: [],
        lastModificationTime: undefined,
      },
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    handleRegionSelect() {
      const toDO = () => {};
      this.$refs.regionSelectModal.selectedRowKeys = this.form.regionIdentifiers;
      this.$refs.regionSelectModal.showCreateDialog(toDO);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    cancel() {
      this.$router.back();
    },
    async listProductSubClassById(id) {
      const headers = {
        'Accept-Language': this.languageSelect,
      };
      const response = await this.$apiManager.productSubClass.getProductSubClassById(id, headers);
      if (!response.data.regionIdentifiers) {
        response.data.regionIdentifiers = [];
      }
      this.form = response.data;
      this.initImages();
    },
    async handleLanguage() {
      await this.listProductSubClassById(this.$route.params.id);
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    initImages() {
      this.solidImages = [];
      this.lineImages = [];
      this.renderedImages = [];
      this.thumbnails = [];
      this.form.solidImageDownloadUrls.forEach((url, index) => {
        const solidImage = {
          url,
          filePath: this.form.solidImages[index],
          name: this.form.solidImages[index],
          uid: -index,
        };
        this.solidImages.push(solidImage);
      });
      this.form.lineImageDownloadUrls.forEach((url, index) => {
        const lineImage = {
          url,
          filePath: this.form.lineImages[index],
          name: this.form.lineImages[index],
          uid: -index,
        };
        this.lineImages.push(lineImage);
      });
      this.form.renderedImageDownloadUrls.forEach((url, index) => {
        const renderedImage = {
          url,
          filePath: this.form.renderedImages[index],
          name: this.form.renderedImages[index],
          uid: -index,
        };
        this.renderedImages.push(renderedImage);
      });
      this.form.thumbnailsDownloadUrls.forEach((url, index) => {
        const thumbnail = {
          url,
          filePath: this.form.thumbnails[index],
          name: this.form.thumbnails[index],
          uid: -index,
        };
        this.thumbnails.push(thumbnail);
      });
    },
    handleProductDetail(product) {
      this.$router.push({ name: 'loadedDeviceDetail', params: { id: product.loadedDeviceTypeId } });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
/deep/.jsoneditor-outer {
  height: 25vw;
}
.title {
  margin-top: 4%;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
</style>
